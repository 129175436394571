import Header from "../components/header/Header";
import Postheader from "../components/postheader/Postheader";
import Main from "../pages/Main";
import About from "../pages/About";
import PublicOffer from "../pages/PublicOffer";
import Footer from "../components/footer/Footer";
import ApartCart from "../components/apart-cart/ApartCart";
import Legend from "../pages/Legend";
import ShippingPayment from "../pages/ShippingPayment";
import Promotion from "../pages/promotion/Promotion";
import MPromotions from "../pages/promotion/MPromotions";
import ModPizza from "../components/mod-pizza/ModPizza";
import Halfs from "../components/mod-halfs/Halfs";
import MHalfsAdd from "../components/mod-halfs/mobile/MHalfsAdd";
import MHalfs from "../components/mod-halfs/mobile/MHalfs";
import MHalfsDelete from "../components/mod-halfs/mobile/MHalfsDelete";
import AuthorsPizza from "../components/mod-authors/AuthorsPizza";
import MAuthorsPizza from "../components/mod-authors/mobile/MAuthorsPizza";
import MAuthorsPizzaAdd from "../components/mod-authors/mobile/MAuthorsPizzaAdd";
import MModPizza from '../components/mod-pizza/mobile/MModPizza.jsx';
import MModPizzaAdd from "../components/mod-pizza/mobile/MModPizzaAdd";


import {
  fetchBorts,
  fetchIngr,
  fetchCatOfIngred,
  fetchPoloPizzas,
  fetchPizza,
  fetchDrinks,
} from "../features/generalSlice";

import { useEffect, useState } from "react";

import useMedia768 from "../hooks/useMedia";

import { useDispatch } from "react-redux";
import { Routes, Route } from "react-router-dom";

//импортируем адрес сервера
import { refOnServer } from '../data-names/data.js';

//4

function App() {
  const isMobile = useMedia768();
  const dispatch = useDispatch();
  const [areWork, setAreWork] = useState(1);

  //при мнтировании - запрос для получения информации
  useEffect(() => {
    dispatch(fetchPizza({ sorting: { cat: "rating", title: 'за рейтингом', order: 'desc' }, filter: null }));
    dispatch(fetchBorts());
    dispatch(fetchIngr());
    dispatch(fetchCatOfIngred());
    dispatch(fetchPoloPizzas());
    dispatch(fetchDrinks());
    fetch(`${refOnServer}/arework`)
      .then((res) => res.json())
      .then((res) => {
        if (!res) setAreWork(0);
      });
  }, []);

  /* const modify = useSelector(state=>state.general.modify); */

  return (
    <>
      <Header />
      <Postheader />
      <main>
        <Routes>
          {/* ниже говорим, что главный роут - ведет на страницу Main, на этой странице есть подроутинг (Оутлет). */}
          <Route path="/" element={<Main />}>

            {isMobile ?
              <Route path="pizzas/:pizzaId" element={<ModPizza />} >
                <Route index element={<MModPizza />} />
                <Route path='add-to-pizza' element={<MModPizzaAdd />} />
              </Route>
              :
              <Route path="pizzas/:pizzaId" element={<ModPizza />} />
            }
            {isMobile ?
              <Route path="/halfs" element={<Halfs />} >
                <Route index element={<MHalfs />} />
                <Route path='add-to-halfs' element={<MHalfsAdd />} />
                <Route path='delete-from-halfs' element={<MHalfsDelete />} />
              </Route>
              :
              <Route path="/halfs" element={<Halfs />} />
            }
            {
              isMobile ?
                <Route path="/constructor" element={<AuthorsPizza />}>
                  <Route index element={<MAuthorsPizza />} />
                  <Route path="adding" element={<MAuthorsPizzaAdd />} />
                </Route>
                :
                <Route path="/constructor" element={<AuthorsPizza />} />
            }
          </Route>

          <Route path="/order" element={<Main />} />

          {/* далее непотимизированно */}
          {isMobile ?
            <Route path="/promotion" element={<MPromotions />} />
            :
            <Route path="/promotion" element={<Promotion />} />
          }

          <Route path="/about" element={<About />} />
          <Route path="/cart" element={<ApartCart />} />
          <Route path="/legend" element={<Legend />} />
          <Route path="/public-offer" element={<PublicOffer />} />
          <Route path="/shipping-payment" element={<ShippingPayment />} />

          {/*   если ничего не подошло - выбрасываем 404*/}
          <Route
            path="*"
            element={
              <section className="main">
                <div className="container">
                  <h2>
                    Схоже, що такої сторінки не існує, або вона була видалена :(
                  </h2>
                </div>
              </section>
            }
          />
        </Routes>
      </main>
      <Footer />

      {/* ниже простой функционал для варианта, когда не работам (в юзЭффект идет фетч) */}
      {!areWork ? (
        <div
          style={{
            position: "fixed",
            width: "100%",
            height: "100%",
            zIndex: 9999,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <span
            style={{ backgroundColor: "#222", padding: "30px", color: "white" }}
          >
            Через надмірну завантаженість прийом замовленнь на сайті тимчасово обмежено.<br></br>Вибачте за незручності.
          </span>
        </div>
      ) : null}

      {/* {modify ? <ModPizza/> : null} */}
    </>
  );
}

export default App;
