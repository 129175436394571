import useMedia768 from "../../hooks/useMedia";
import useExtrasPriceSum from "../../hooks/useExtrasPriceSum";

import { Outlet, useNavigate } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { useSelector } from "react-redux";

import PCAuthorsPizza from "./pc/PCAuthorsPizza";

import './authorsPizza.scss';

import authorsImg from '../../assets/constructor/authors800.webp';

export default function AuthorsPizza() {
  const isMobile = useMedia768();
  const navigate = useNavigate();
  //получаем параметры http запроса, ранее я использовал модифайАЙди, которое получал из редакс стэйта, но пока что так, чтобы была история...


  //здесь используются бортики, поэтому будем формировать их локально
  const [bortIndex, setBortIndex] = useState("0");
  //для описания пиццы мы используем числовые обозначения, которые в последствии преобразуем в слова, используя объект из базы данных
  const ingr = useSelector((state) => state.general.ingr);
  //получаем борты также из базы данных
  const borts = useSelector((state) => state.general.borts);



  //ниже мы также создадим стэйт для счетчика количества пицц, которое мы добавим в корзину
  const [countModPizza, setCountModPizza] = useState(1);

  //когда открывается модальное окно, мы будем убирать скролл с основной части сайта. При размонтировании скрол будет снова добавляться.
  useEffect(() => {
    document.body.style.overflow = "hidden";
    return () => {
      document.body.style.overflow = "auto";
    };
  }, []);



  //ниже моя уродливая проверка на то, получены ли элементы ()
  const loadingBorts = useSelector((state) => state.general.loadingBorts);
  const loadingCatOfIngred = useSelector(
    (state) => state.general.loadingCatOfIngred
  );
  const loadingIngr = useSelector((state) => state.general.loadingIngr);

  //проверка окончена
  //ниже создаем локальный РЕАКТ СТЭЙТ, чтобы сюда помещать ингредиенты, которые ДОБАВЛЕНЫ. Это отдельная информация, которая будет формировать уникальный АЙДИ для объекта в корзине, а также будет использоваться в верстке последней.
  const [extraIngr, setExtraIngr] = useState({});
  const extrasPriceSum = useExtrasPriceSum(extraIngr);

  useEffect(() => {
    if (loadingIngr === 'ok') {
      setExtraIngr({
        '75q': [{ idOfIngr: '75q', price: ingr['75q'][1] }], '81q': [{ idOfIngr: '81q', price: ingr['81q'][1] }, { idOfIngr: '81q', price: ingr['81q'][1] }]

      })
    }
  }, [loadingIngr === 'ok'])

  //ниже проверка - если произошла ошибка в фетчинге, то вернет пустоту (ведь с этими сущностями мы произодим проверки) - ПОСМОТРИ ВЫШЕ. МОЖНО ОПТИМИЗИРОВАТЬ!
  if (
    loadingBorts !== "ok" ||
    loadingIngr !== "ok"
  ) {
    return null;
  }



  const id = 'authorspizza';
  const img = authorsImg;
  const name = 'Авторська піца';
  const startPrice = 70;

  //ниже формируем айди, которое будет создавать в корзине уникальную запись, преобразовываем объект в массив, чтобы можно было сортировать. Сортируем для того, чтобы последовательность всегда была одинаковой. Ведь, если из модального окна в корзину дважды будет добавлена пицца без сыра, то в корзине должен быть только один массив с двумя объектами такой пиццы. То есть запись одна, а количество равное двум. **Все это из-за того, что порядок свойств объекта при переборе может случайным образом измениться - это ведь объект, а не массив. Поэтому может случиться так, что будут добавлены две одинаковые пиццы с одинаковыми дополнительными ингредиентами, однако получат разные айди. Поэтому сортируем.

  const fullId =
    id +
    bortIndex +
    Object.keys(extraIngr)
      .sort((a, b) => a - b)
      .join("") +
    Object.keys(extraIngr)
      .sort((a, b) => a - b)
      .map((item) => extraIngr[item].length)
      .join("");

  //ниже считаем цену финальную
  const modPrice =
    (startPrice + extrasPriceSum + borts[bortIndex].price) * countModPizza;
  const priceForOne = startPrice + extrasPriceSum + borts[bortIndex].price;


  if (isMobile) {
    return <Outlet context={{ navigate, img, borts, bortIndex, setBortIndex, extraIngr, setExtraIngr, ingr, name, modPrice, countModPizza, setCountModPizza, fullId, priceForOne }} />
  }
  else {
    return <PCAuthorsPizza navigate={navigate} img={img} borts={borts} bortIndex={bortIndex} setBortIndex={setBortIndex} extraIngr={extraIngr} setExtraIngr={setExtraIngr} ingr={ingr} name={name} modPrice={modPrice} countModPizza={countModPizza} setCountModPizza={setCountModPizza} fullId={fullId} />
  }
}
