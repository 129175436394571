import "./shipping-payment.scss";

import { useEffect } from "react";

const ShippingPayment = () => {

  useEffect(() => {
    window.scrollTo({ top: 0, lef: 0, behavior: "smooth" });
  }, []);

  return (
    <section className="shipping-payment">
      <div className="container">
        <h1 className="shipping-payment__title">Доставка й оплата</h1>
        <div className="shipping-payment__text">
          <h2 className="shipping-payment__subtitle"> Доставка</h2>
          <br />
          Доставка замовленої покупцем продукції здійснюється транспортним засобом закладу щоденно з 10:30 до 18:00.
          <br />
          <br />
          Зона обслуговування включає місто Добропілля та селище Ганнівка.
          Доставка замовлень за межі цих населених пунктів незалежно від відстані – не допускається.
          <br /><br />
          Час очікування визначається індивідуально для кожного замовлення й оговорюється з оператором в телефонній розмові.
          <br /><br />
          Вартість доставки в межах міста Добропілля становить від 60 до 100 гривень в залежності від району міста, в межах Ганнівки – 200 гривень фіксовано.
          <br />
          <br />
          <h2 className="shipping-payment__subtitle"> Оплата</h2>
          <br />
          Оплата за товар може бути здійснена онлайн на сайті через платіжний шлюз LiqPay або безпосередньо кур'єру готівкою або безготівковим розрахунком через міні-термінал.

        </div>

      </div>
    </section >
  );
};

export default ShippingPayment;
